import { useContext, useEffect, useState } from "react";
//npm imports
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";

//component imports
import HomePageHero from "./Blocks/HomepageHero/HomepageHero";
import Faq from "./Blocks/FAQ/FAQ";
import Footer from "../../Components/Footer/Footer";

//other imports
import { GlobalContext } from "../../Context/GlobalContext";
import ChooseAcademyTest from "./Blocks/ChooseAcademyTest/ChooseAcademyTest";
import HomePageHeader from "./Blocks/HomePageHeader/HomePageHeader";
import TestimonialCarousel from "./Blocks/TestimonialCarousel/TestimonialCarousel";
import CountDown from "./Blocks/CountDown/CountDown";
import Awards from "./Blocks/Awards/Awards";
import SNPLSection from "./Blocks/SNPLSection/SNPLSection";

// styled-components
const StyledContainer = styled.section`
  width: 100%;
`;

const Homepage = () => {
  let params = useParams();
  // context
  const { userInfo, setUserInfo } = useContext(GlobalContext);
  const [scrollToTests, setScrollToTests] = useState(false);

  useEffect(() => {
    if (params.referralGuid) {
      setUserInfo({ ...userInfo, referred_by: params.referralGuid });
    }
    //eslint-disable-next-line
  }, [params.referralGuid]);

  useEffect(() => {
    const queryString = window.location.search;

    // Removes the '?' from the start of the string
    const cleanedQueryString = queryString.substring(1);

    if (cleanedQueryString !== "") {
      localStorage.setItem("queryString", cleanedQueryString);
    }
  }, []);

  const resetButton = () => setScrollToTests(false);
  const handleScrollToSection = () => {
    setScrollToTests(true);
  };
  return (
    <StyledContainer>
      <HomePageHeader handleScrollToSection={handleScrollToSection} />
      <HomePageHero handleScrollToSection={handleScrollToSection} />
      <ChooseAcademyTest
        resetButton={resetButton}
        scrollToTests={scrollToTests}
      />
      {/* <Awards /> */}
      <SNPLSection handleScrollToSection={handleScrollToSection} />
      <TestimonialCarousel handleScrollToSection={handleScrollToSection} />
      <Faq />
      <CountDown handleScrollToSection={handleScrollToSection} />
      <Footer />
    </StyledContainer>
  );
};

export default Homepage;
