//npm imports
import styled, { css } from "styled-components/macro";
import { ButtonText12, ButtonText14, ButtonText16 } from "../Text/Text";

//styled components
const StyledButtonBasic = styled.button`
  ${(props) =>
    props.variation === "small"
      ? css`
          padding: 10px 20px;
        `
      : props.variation === "large"
      ? css`
          padding: 14px 30px;
        `
      : props.variation === "extra-large" &&
        css`
          padding: 16px 32px;
        `}
  background-color: ${(props) =>
    props.filling === "black" ? props.theme.black : props.theme.darkBlue};
  border: none;
  border-radius: 12px;
  box-shadow: 0px 8px 14px 0px rgba(116, 151, 255, 0.50);

  &:hover {
    cursor: pointer;
    background: ${(props) =>
      props.filling === "black"
        ? "linear-gradient(89deg, #6A6A6A -3.89%, #000 102.67%)"
        :  props.filling === "altBlue" ? "linear-gradient(88deg, #7497FF -10.97%, #4B4BFF 108.4%)" : ""};
  }
`;

//main-component
const ButtonBasic = ({ onClick, text, variation, filling, className }) => {
  return (
    <StyledButtonBasic
      className={className}
      variation={variation}
      onClick={onClick}
      filling={filling}
    >
      {variation === "small" ? (
        <ButtonText12>{text}</ButtonText12>
      ) : variation === "large" ? (
        <ButtonText14>{text}</ButtonText14>
      ) : (
        variation === "extra-large" && <ButtonText16>{text}</ButtonText16>
      )}
    </StyledButtonBasic>
  );
};

export default ButtonBasic;
