const theme = {
    gray: "#C6C6C6",
    veryLightGray: "#EEEEEE",
    lightestGray: "#F9F9F9",

    textGray: "#B2B2B2",
    altYellow: "#F5EB5D",
    brightYellow: "#F9F39A",

    GraphicDesign: "#FF7A45",
    DigitalMarketing: "#E8103F",
    Fullstack: "#80ECEA",
    Frontend: "#80ECEA",
    DataScience: "#4D64EF",
    SoftwareTesting: "#04BF68",
    UXUI: "#183FF3",
    ProductManagement: "#D1F00C",
    HR: "#577A80",
    BusinessIntelligence: "#F2E635",

    active: "#005EFF",
    textError: "#FE3346",

    headerLarge40: 40,
    headerLarge40LineHeight: 46,
    header32: 32,
    header32LineHeight: 37,
    headerMedium28: 28,
    headerMedium28LineHeight: 32,
    headerMedium24: 24,
    headerMedium24LineHeight: 28,
    headerSmall24: 24,
    headerSmall24LineHeight: 28,
    subheader18: 18,
    subheader18LineHeight: 21,
    caption12: 12,
    caption12LineHeight: 14,
    labelCaps12: 12,
    labelCaps12LineHeight: 14,
    text12: 12,
    text12LineHeight: 14,
    text14: 14,
    text14LineHeight: 16,
    text16: 16,
    text16LineHeight: 19,
    text18: 18,
    text18LineHeight: 21,
    buttonText12: 12,
    buttonText12LineHeight: 14,
    buttonText14: 14,
    buttonText14LineHeight: 16,
    buttonText16: 16,
    buttonText16LineHeight: 18,



    white: '#ffffff',
    beige: '#FFEED8',
    gray1: "#B9BBC4",
    gray2: "#5D6174",
    gray3: "#E1E2E7",
    gray4: "#61626d",
    gray5: "#D9D9D9",
    gray6: "#E6E7EB",
    yellow: '#FED445',
    pink: '#FFA9C4',
    red: '#F43C28',
    coralRed: "#ED5050",
    dark_blue: "#484BFF",
    blue: '#234BDB',
    altBlue: "#7497FF",
    darkBlue: "#2F64FF",
    lightBlue: "#e9edfb",
    violet: "#4B4BFF",
    green: '#206246',
    success: "#6FCF97",
    orange: "#F2994A",
    danger: "#FFA9C4",
    incorrect: "#FE3346",
    purple: "#454BFF",
    danger_select: "#F3F3F5",
    black: '#000000',
    black2: "#252424",
    black3: "#09090B",
    darkGray: "#2B2B2B",
    lightBlack: '#302F38',
    lightDark: "#2C2C31",
    altBlack: "#46415C",
    mediumBlack: '#3E3853',
    darkBlack: '#100824',
    silverGradient: "#EDEDF6"
}

export default theme;