import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import theme from "../../../../Consts/Theme";
import heroArrowsMobile from "./Static/mobile/hero-arrows-mobile.svg";
import studentGirl from "./Static/mobile/student-girl.png";
import studentBoy from "./Static/mobile/student-boy.png";
import titleLine1 from "./Static/mobile/title-line-1.svg";
import titleLine2 from "./Static/mobile/title-line-2.svg";
import titleLine3 from "./Static/mobile/title-line-3.svg";
import HeroCardNumbers from "./HeroCardNumbers";
import ButtonBasic from "../../../../Components/ButtonBasic/ButtonBasic";
import Wrapper from "../../../../Components/Wrapper/Wrapper";
import HeroCardMaxStudents from "./HeroCardMaxStudents";

// styled-components
const StyledContainer = styled.section`
  background-color: black;
  background-image: url(${heroArrowsMobile});

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 80px;
  padding-bottom: 60px;
  min-height: 100vh;
  position: relative;

  header {
    display: flex;
    justify-content: end;
    position: absolute;
    top: 60px;
    right: 20px;
  }

  .student {
    margin-left: 16px;
  }

  header img,
  .student {
    border-radius: 50%;
    width: 100px;
  }
`;
const StyledHeadingContainer = styled.div`
  margin-top: 100px;
  margin-bottom: 24px;

  .second-line-container {
    display: flex;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .third-line-container {
    display: flex;
    justify-content: end;
    margin-bottom: 24px;
  }

  p {
    color: #fff;
    font-family: Raleway;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }
  @media (max-width: 374px) {
    img.first-title-line {
      width: 290px;
    }
    .second-line-container img {
      width: 120px;
    }
    .second-line-container img.student {
      width: 60px;
    }
    .third-line-container img {
      width: 250px;
    }
  }
  @media (min-width: 375px) and (max-width: 424px) {
    img.first-title-line {
      width: 330px;
    }
    .second-line-container img.student {
      width: 80px;
    }
  }
  @media (min-width: 425px) {
    img.first-title-line {
      width: 80%;
    }
    .second-line-container img {
      width: 30%;
    }
    .second-line-container img.student {
      width: 80px;
    }
    .third-line-container img {
      width: 70%;
  }
  }
`;

const StyledCTAContainer = styled.div`
  width: 100%;
  div {
    width: 100%;
  }
  button {
    width: 100%;
  }
`;

const StyledFooter = styled.footer`
  margin-top: 32px;
`;

const HomepageHeroMobile = ({
  handleScrollToSection,
  landingNumbers,
  isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <Wrapper>
        {/* --------------------------------- Main Heading ---------------------- */}

        <header>
          <img src={studentGirl} alt="" />
        </header>
        {/* Heading Part */}
        <StyledHeadingContainer>
          <img src={titleLine1} alt="" className="first-title-line" />
          <div className="second-line-container">
            <img src={titleLine2} alt="" />
            <img src={studentBoy} alt="" className="student" />
          </div>
          <div className="third-line-container">
            <img src={titleLine3} alt="" />
          </div>
          <p>
            Студирај БЕСПЛАТНО на <br /> Brainster Next - практичен <br />
            IT факултет!
          </p>
        </StyledHeadingContainer>
        {/* CTA Button */}
        <StyledCTAContainer>
          <ButtonBasic
            variation="extra-large"
            filling={"altBlue"}
            text={t("homepage.hero_section.cta")}
            onClick={handleScrollToSection}
          />
        </StyledCTAContainer>
        {/* Footer */}
        <StyledFooter>
          <HeroCardNumbers numbers={landingNumbers} bgColor={theme.lightDark} />
          <HeroCardMaxStudents />
        </StyledFooter>
      </Wrapper>
    </StyledContainer>
  );
};

export default HomepageHeroMobile;
