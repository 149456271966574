import React, { useEffect, useState } from "react";

//npm imports
import { useTranslation } from "react-i18next";
import { withTheme } from "styled-components/macro";

//component imports
import { API } from "../../../../Consts/Api";

//other imports
import useMediaCustom from "../../../../Hooks/useMediaCustom";
import HomepageHeroDesktop from "./HomepageHeroDesktop";
import HomepageHeroMobile from "./HomepageHeroMobile";

const HomePageHero = ({ handleScrollToSection }) => {
  const { t } = useTranslation();
  const isLaptop = useMediaCustom("(min-width: 992px)");
  const [landingNumbers, setLandingNumbers] = useState();

  console.log('landingNumbers', landingNumbers)

  useEffect(() => {
    API.axios
      .get(API.createExternalLink("landingNumbers"))
      .then((response) => {
        console.log('response', response.data.snpl_registrants)
        setLandingNumbers(response?.data?.snpl_registrants);
      })
      .catch((error) => console.error(error));
  }, []);

  return isLaptop ? (
    <HomepageHeroDesktop
      landingNumbers={landingNumbers}
      handleScrollToSection={handleScrollToSection}
    />
  ) : (
    <HomepageHeroMobile
      landingNumbers={landingNumbers}
      handleScrollToSection={handleScrollToSection}
    />
  );
};

export default withTheme(HomePageHero);
