import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Wrapper from "../../../../Components/Wrapper/Wrapper";
import theme from "../../../../Consts/Theme";

import heroTitleDesktop from "./Static/hero-title-desktop.svg";
import heroArrowsDesktop from "./Static/hero-arrows-desktop.svg";
import student1Desktop from "./Static/student-1-desktop.png";
import student2Desktop from "./Static/student-2-desktop.png";
import HeroCardNumbers from "./HeroCardNumbers";
import HeroCardMaxStudents from "./HeroCardMaxStudents";

// styled-components
const StyledContainer = styled.section`
  background-color: black;
  background-image: url(${heroArrowsDesktop});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding-top: 110px;
  padding-bottom: 200px;
  min-height: 100vh;

  .left-vector {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .ml-40 {
    margin-left: 40px;
  }
  .mb-8 {
    margin-bottom: 8px;
  }

  .flex-align-center {
    display: flex;
    align-items: center;
  }
`;
const StyledHeroTitleDesktop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;
  div.heading-container {
    position: relative;
  }
  div.heading-container img.img-student-1 {
    position: absolute;
    left: 50px;
    bottom: 0;
    border-radius: 50%;
    width: 90px;
    height: 90px;
  }
  div.subheading-container {
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
    color: #fff;
    font-family: Raleway;
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    margin-top: 48px;
    padding-right: 50px;
  }
  div.subheading-container img.img-student-2 {
    border-radius: 50%;
    width: 90px;
    height: 90px;
  }
`;
const StyledStatisticsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;


`;


const HomepageHeroDesktop = ({ handleScrollToSection, landingNumbers }) => {
  const { t } = useTranslation();

  console.log('landingNumbers hero desktop', landingNumbers)

  return (
    <StyledContainer>
      <Wrapper>
        {/* --------------------------------- Main Heading ---------------------- */}
        <header>
          <StyledHeroTitleDesktop>
            <div>
              <div className="heading-container">
                <img src={heroTitleDesktop} alt="" />
                <img
                  src={student1Desktop}
                  alt="Student Image"
                  className="img-student-1"
                />
              </div>
              <div className="subheading-container">
                <p>
                  Студирај БЕСПЛАТНО!
                  <br />
                  Brainster Next - практичен IT факултет!
                </p>
                <img
                  src={student2Desktop}
                  alt="Student Image"
                  className="img-student-2"
                />
              </div>
            </div>
            <StyledStatisticsContainer>
              <HeroCardNumbers
                numbers={landingNumbers}
                content={t(
                  "homepage.hero_section.hero_card_mobile.test_takers"
                )}
                bgColor={theme.lightDark}
              />
              <HeroCardMaxStudents />

            </StyledStatisticsContainer>
          </StyledHeroTitleDesktop>
        </header>
      </Wrapper>
    </StyledContainer>
  );
};

export default HomepageHeroDesktop;
