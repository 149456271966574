import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import theme from "../../../../Consts/Theme";
import useMediaCustom from "../../../../Hooks/useMediaCustom";
import CountDownCard from "./CountDownCard";
import useCountdown from "../../../../Hooks/useCountDown";
import { GlobalContext } from "../../../../Context/GlobalContext";
import ButtonBasic from "../../../../Components/ButtonBasic/ButtonBasic";
import glassesRight from "./Static/glassesRight.svg";
import glassesLeft from "./Static/glassesLeft.svg";

// styled-components
const StyledContainer = styled.section`
  background-color: ${theme.black};
  overflow: hidden;
`;
const StyledInnerContainer = styled.section`
  width: 90%;
  max-width: 1235px;
  margin-left: auto;
  margin-right: auto;
  font-family: "Inter";
  font-style: normal;
  text-align: center;

  .go-to-test-button-container {
    padding-bottom: 150px;
  }

  p.rules {
    color: #b9bbc4;
    text-align: center;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-top: 24px;
    cursor: pointer;
  }

  .test-deadline {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 16px;
  }
`;
const StyledHeading = styled.h2`
  color: ${theme.white};
  font-size: 50px;
  font-weight: 400;

  @media (max-width: 550px) {
    font-size: 30px;
  }
`;
const StyledCardsWrap = styled.div`
  margin-top: 150px;
  margin-bottom: 150px;
  position: relative;

  img {
    position: absolute;
    display: block;
  }

  img.glasses-left {
    bottom: -70%;
    left: -15%;
  }
  img.glasses-right {
    top: -5%;
    right: -16%;
  }

  .final-deadline {
    color: ${theme.gray4};
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: 0.56px;
    margin-top: 20px;
  }

  @media (max-width: 550px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  @media (max-width: 992px) {
    position: static;
    img {
      display: none;
    }
  }
`;
const StyledCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 16px;
  @media (max-width: 550px) {
    column-gap: 5px;
  }
`;

const CountDown = ({ handleScrollToSection }) => {
  const { t } = useTranslation();
  const { term, showAllRulesPopup, setShowAllRulesPopup } =
    useContext(GlobalContext);

  const isLaptop = useMediaCustom("(min-width: 992px)");
  const [days, hours, minutes, seconds] = useCountdown(term.end_date);

  const counterArray = [
    {
      id: 1,
      number: days,
      description: "денови",
    },
    {
      id: 2,
      number: hours,
      description: "часови",
    },
    {
      id: 3,
      number: minutes,
      description: "минути",
    },
    {
      id: 4,
      number: seconds,
      description: "секунди",
    },
  ];

  return (
    <StyledContainer>
      <StyledInnerContainer>
        <StyledHeading
        // dangerouslySetInnerHTML={{
        //   __html: t(
        //     `homepage.countdown.header_${isLaptop ? `desktop` : `mobile`}`
        //   ),
        // }}
        >
          За оние кои не се плашат да иновираат, <br /> менуваат и прашуваат!
        </StyledHeading>
        <p className="test-deadline">
          Brainster Next- Не се плашиме да сме различни!
          {/* {t("homepage.countdown.test_deadline")} */}
        </p>
        <StyledCardsWrap>
          <StyledCardsContainer>
            {counterArray.map((item) => (
              <CountDownCard
                key={item.id}
                number={item.number}
                description={item.description}
              />
            ))}
          </StyledCardsContainer>
          <p className="final-deadline">
            {t("homepage.countdown.final_deadline")}
          </p>
          <img src={glassesLeft} alt="" className="glasses-left" />
          <img src={glassesRight} alt="" className="glasses-right" />
        </StyledCardsWrap>
        <div className="go-to-test-button-container">
          <ButtonBasic
            variation="extra-large"
            filling={"altBlue"}
            text={t("homepage.greatest_challenge_in_life.cta")}
            onClick={handleScrollToSection}
          />
          <p
            className="rules"
            onClick={() =>
              setShowAllRulesPopup({
                ...showAllRulesPopup,
                display: true,
              })
            }
          >
            Прочитај ги правилата
          </p>

          {/* <ButtonBasic
            variation={"extra-large"}
            text={t("general.rules")}
            className="btn-rules"
          /> */}
        </div>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default CountDown;
