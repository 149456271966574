//main component
const BrainsterNextLogo = ({ color, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="32"
      viewBox="0 0 150 32"
      fill="none"
    >
      <path
        d="M8.88602 25.0578C8.7625 24.9831 8.7625 24.8585 8.86131 24.7838C9.6024 24.2608 10.0718 23.4886 10.0718 22.3678C10.0718 20.4251 8.7378 18.9805 6.1193 18.9805H0.46234C0.264717 18.9805 0.0917969 19.1548 0.0917969 19.3541V30.8611C0.0917969 31.0604 0.264717 31.2347 0.46234 31.2347H6.66276C9.13305 31.2347 10.6152 29.815 10.6152 27.7726C10.6152 26.4526 9.94824 25.5559 8.88602 25.0578ZM3.20436 22.1437C3.20436 21.9693 3.35258 21.8199 3.5255 21.8199H5.94638C6.58865 21.8199 6.93449 22.2184 6.93449 22.7912C6.93449 23.3641 6.61335 23.7626 5.77346 23.7626H3.5255C3.35258 23.7626 3.20436 23.6132 3.20436 23.4388V22.1437ZM6.31692 28.3953H3.5255C3.35258 28.3953 3.20436 28.2459 3.20436 28.0715V26.5522C3.20436 26.3779 3.35258 26.2284 3.5255 26.2284H6.34162C7.18152 26.2284 7.50266 26.6518 7.50266 27.2745C7.47795 27.9221 7.15682 28.3953 6.31692 28.3953Z"
        fill="white"
      />
      <path
        d="M21.0137 27.7976C20.9149 27.6481 20.9396 27.4738 21.1126 27.399C22.57 26.602 23.237 25.0827 23.237 23.4637C23.237 21.0228 21.8042 18.9805 18.6917 18.9805H13.1088C12.9112 18.9805 12.7383 19.1548 12.7383 19.3541V30.8611C12.7383 31.0604 12.9112 31.2347 13.1088 31.2347H15.4556C15.6532 31.2347 15.8261 31.0604 15.8261 30.8611V28.2708C15.8261 28.0964 15.9744 27.947 16.1473 27.947H17.1107C17.3083 27.947 17.5059 28.0466 17.6047 28.1961L19.5069 30.9109C19.6551 31.1102 19.8774 31.2347 20.0997 31.2347H23.0641C23.2123 31.2347 23.3111 31.0604 23.2123 30.9109L21.0137 27.7976ZM18.5188 25.1076H16.172C15.9991 25.1076 15.8508 24.9582 15.8508 24.7838V22.1437C15.8508 21.9693 15.9991 21.8199 16.172 21.8199H18.5188C19.7539 21.8199 20.2232 22.5173 20.2232 23.4637C20.2232 24.3355 19.7539 25.1076 18.5188 25.1076Z"
        fill="white"
      />
      <path
        d="M32.4527 19.3292C32.3786 19.1299 32.1563 18.9805 31.934 18.9805H29.5872C29.3649 18.9805 29.1425 19.1299 29.0684 19.3292L24.4243 30.886C24.3502 31.0355 24.4737 31.2347 24.6466 31.2347H27.2898C27.5121 31.2347 27.7098 31.0853 27.7839 30.886L28.2285 29.7403C28.3026 29.5659 28.4509 29.4414 28.6485 29.4414H32.8233C33.0209 29.4414 33.1938 29.5659 33.2432 29.7403L33.6879 30.886C33.762 31.0853 33.9596 31.2347 34.1819 31.2347H36.8251C37.0228 31.2347 37.1216 31.0355 37.0475 30.886L32.4527 19.3292ZM31.761 26.602H29.7107C29.5625 26.602 29.4637 26.4526 29.5131 26.3031L30.6247 23.3143C30.6494 23.2147 30.8223 23.2147 30.847 23.3143L31.9587 26.3031C32.0328 26.4277 31.934 26.602 31.761 26.602Z"
        fill="white"
      />
      <path
        d="M41.6658 18.9805H39.2944C39.0967 18.9805 38.9238 19.1548 38.9238 19.3541V30.8611C38.9238 31.0604 39.0967 31.2347 39.2944 31.2347H41.6658C41.8635 31.2347 42.0364 31.0604 42.0364 30.8611V19.3541C42.0364 19.1548 41.8635 18.9805 41.6658 18.9805Z"
        fill="white"
      />
      <path
        d="M55.4775 18.9805H53.1307C52.9331 18.9805 52.7602 19.1548 52.7602 19.3541V25.5061C52.7602 25.6057 52.6366 25.6555 52.5625 25.5808L47.5726 19.2794C47.4243 19.0801 47.202 18.9805 46.955 18.9805H45.0776C44.88 18.9805 44.707 19.1548 44.707 19.3541V30.8611C44.707 31.0604 44.88 31.2347 45.0776 31.2347H47.4243C47.622 31.2347 47.7949 31.0604 47.7949 30.8611V24.7091C47.7949 24.6095 47.9184 24.5596 47.9925 24.6344L53.0072 30.9109C53.1554 31.1102 53.3777 31.2098 53.6248 31.2098H55.5022C55.6998 31.2098 55.8727 31.0355 55.8727 30.8362V19.3541C55.848 19.1548 55.6751 18.9805 55.4775 18.9805Z"
        fill="white"
      />
      <path
        d="M63.4537 23.54C61.9963 23.0418 61.4528 22.7927 61.4528 22.2946C61.4528 21.8961 61.8727 21.4727 62.935 21.4727C63.9725 21.4727 64.5654 21.9459 64.7383 22.469C64.8371 22.7429 64.9606 22.8924 65.257 22.8924H67.4309C67.6532 22.8924 67.8508 22.6682 67.8261 22.444C67.6779 20.2522 65.7017 18.7578 62.8609 18.7578C60.0941 18.7578 58.1179 20.2273 58.1179 22.5686C58.1179 25.1091 60.6623 26.0307 62.5891 26.6782C64.0466 27.1764 64.6642 27.5002 64.6642 28.0232C64.6642 28.3968 64.2936 28.7704 63.1326 28.7704C62.0457 28.7704 61.4034 28.3968 61.1811 27.9734C60.9834 27.6496 60.9093 27.5749 60.5635 27.5749H58.4637C58.2414 27.5749 58.0438 27.7991 58.0685 28.0232C58.2908 30.2648 60.4647 31.4853 63.2067 31.4853C65.9734 31.4853 68.0485 30.2399 68.0485 27.8489C67.9991 25.134 65.1335 24.1377 63.4537 23.54Z"
        fill="white"
      />
      <path
        d="M79.7347 18.9805H69.8041C69.6065 18.9805 69.4336 19.1548 69.4336 19.3541V21.6455C69.4336 21.8448 69.6065 22.0191 69.8041 22.0191H72.9167C73.0896 22.0191 73.2378 22.1686 73.2378 22.3429V30.8611C73.2378 31.0604 73.4108 31.2347 73.6084 31.2347H75.9799C76.1775 31.2347 76.3504 31.0604 76.3504 30.8611V22.3429C76.3504 22.1686 76.4986 22.0191 76.6715 22.0191H79.7841C79.9817 22.0191 80.1546 21.8448 80.1546 21.6455V19.3541C80.1299 19.1548 79.957 18.9805 79.7347 18.9805Z"
        fill="white"
      />
      <path
        d="M90.9749 18.9805H82.3784C82.1807 18.9805 82.0078 19.1548 82.0078 19.3541V30.8611C82.0078 31.0604 82.1807 31.2347 82.3784 31.2347H90.9749C91.1726 31.2347 91.3455 31.0604 91.3455 30.8611V28.5946C91.3455 28.3953 91.1726 28.221 90.9749 28.221H85.4415C85.2686 28.221 85.1204 28.0715 85.1204 27.8972V26.9507C85.1204 26.7764 85.2686 26.6269 85.4415 26.6269H89.8633C90.0609 26.6269 90.2339 26.4526 90.2339 26.2533V24.1611C90.2339 23.9619 90.0609 23.7875 89.8633 23.7875H85.4415C85.2686 23.7875 85.1204 23.6381 85.1204 23.4637V22.3429C85.1204 22.1686 85.2686 22.0191 85.4415 22.0191H90.9749C91.1726 22.0191 91.3455 21.8448 91.3455 21.6455V19.3541C91.3455 19.1548 91.1726 18.9805 90.9749 18.9805Z"
        fill="white"
      />
      <path
        d="M104.142 30.9143L101.919 27.7761C101.82 27.6266 101.845 27.4523 102.018 27.3775C103.475 26.5805 104.142 25.0612 104.142 23.4422C104.142 21.0014 102.709 18.959 99.5967 18.959H94.0385C93.8409 18.959 93.668 19.1333 93.668 19.3326V30.8396C93.668 31.0389 93.8409 31.2132 94.0385 31.2132H96.3853C96.5829 31.2132 96.7558 31.0389 96.7558 30.8396V28.2493C96.7558 28.0749 96.904 27.9255 97.077 27.9255H98.0404C98.238 27.9255 98.4356 28.0251 98.5344 28.1746L100.437 30.8894C100.585 31.0887 100.807 31.2132 101.029 31.2132H103.994C104.142 31.2381 104.241 31.0638 104.142 30.9143ZM99.4237 25.111H97.0523C96.8793 25.111 96.7311 24.9616 96.7311 24.7872V22.1471C96.7311 21.9727 96.8793 21.8233 97.0523 21.8233H99.399C100.634 21.8233 101.104 22.5207 101.104 23.4671C101.128 24.3389 100.659 25.111 99.4237 25.111Z"
        fill="white"
      />
      <path
        d="M116.77 19.0293V31.2835H114.226L108.372 19.6271V31.2835H106.914V19.0293H109.458L115.313 30.6858V19.0293H116.77Z"
        fill="white"
      />
      <path
        d="M120.723 20.2746V24.2598H126.652V25.5051H120.723V30.0382H127.343V31.2835H119.191V19.0293H127.343V20.2746H120.723Z"
        fill="white"
      />
      <path
        d="M135.398 24.9567L139.103 31.2581H137.399L134.064 25.3552L130.531 31.2581H128.926L132.73 24.9567L129.099 19.0039H130.803L134.089 24.5582L137.325 19.0039H138.98L135.398 24.9567Z"
        fill="white"
      />
      <path
        d="M149.776 20.3494H145.971V31.2586H144.44V20.3494H140.66V19.0293H149.776V20.3494Z"
        fill="white"
      />
      <path
        d="M145.964 12.864H144.309V1.81115H145.964V12.864ZM149.999 1.98358H140.291V0.535156H149.999V1.98358Z"
        fill="white"
      />
      <path
        d="M138.91 12.864H130.582V0.535156H138.91V1.96634H132.237V11.4328H138.91V12.864ZM138.583 7.29447H132.065V5.86329H138.583V7.29447Z"
        fill="white"
      />
      <path
        d="M124.374 12.864H122.719V1.81115H124.374V12.864ZM128.409 1.98358H118.701V0.535156H128.409V1.98358Z"
        fill="white"
      />
      <path
        d="M105.75 12.864V11.4156H106.629C106.94 11.4156 107.17 11.3466 107.319 11.2087C107.48 11.0592 107.584 10.8236 107.63 10.5017L108.854 0.535156H116.51V12.864H114.854V0.862776L115.872 1.98358H109.319L110.44 0.862776L109.199 10.7086C109.141 11.2029 109.015 11.611 108.819 11.9329C108.635 12.2432 108.377 12.4789 108.043 12.6398C107.721 12.7893 107.319 12.864 106.836 12.864H105.75Z"
        fill="white"
      />
      <path
        d="M96.6795 12.864V11.4501H98.1451C98.5245 11.4501 98.8463 11.3926 99.1107 11.2776C99.3751 11.1627 99.6108 10.973 99.8177 10.7086C100.025 10.4327 100.243 10.0591 100.473 9.5878L104.853 0.535156H106.577L101.714 10.312C101.404 10.9443 101.088 11.4501 100.766 11.8294C100.444 12.1973 100.082 12.4617 99.6798 12.6226C99.2774 12.7835 98.7774 12.864 98.1796 12.864H96.6795ZM100.559 10.0879L95.6621 0.535156H97.3864L101.835 9.3464L100.559 10.0879Z"
        fill="white"
      />
      <path
        d="M86.2667 12.864H84.6113V0.535156H86.2667V12.864ZM95.0779 12.864H92.6811L85.8528 6.4668H86.1115L92.2156 0.535156H94.3537L87.8703 6.69096V6.24264L95.0779 12.864Z"
        fill="white"
      />
      <path
        d="M80.0399 9.65678H73.1426V8.22559H80.0399V9.65678ZM82.4539 12.864H80.6951L76.2981 1.60423H76.9189L72.5219 12.864H70.832L75.7291 0.535156H77.5396L82.4539 12.864Z"
        fill="white"
      />
      <path
        d="M63.469 11.6046C61.3079 11.6046 59.664 11.1908 58.5375 10.3631C57.4109 9.52396 56.8477 8.2997 56.8477 6.69034C56.8477 5.08098 57.4109 3.86246 58.5375 3.03479C59.664 2.20712 61.3079 1.79329 63.469 1.79329C65.6417 1.79329 67.2855 2.20712 68.4006 3.03479C69.5156 3.86246 70.0731 5.08098 70.0731 6.69034C70.0731 8.2997 69.5156 9.52396 68.4006 10.3631C67.2855 11.1908 65.6417 11.6046 63.469 11.6046ZM63.469 10.1562C65.1359 10.1562 66.3716 9.87457 67.1763 9.31129C67.9925 8.74802 68.4006 7.87437 68.4006 6.69034C68.4006 5.5178 67.9925 4.6499 67.1763 4.08662C66.3601 3.52335 65.1244 3.24171 63.469 3.24171C61.8137 3.24171 60.5779 3.52335 59.7617 4.08662C58.9456 4.6499 58.5375 5.5178 58.5375 6.69034C58.5375 7.85138 58.9456 8.71928 59.7617 9.29405C60.5894 9.86882 61.8252 10.1562 63.469 10.1562ZM62.6414 13.3979V11.208L62.6931 10.4493V2.91409L62.6414 2.24161V0H64.2967V2.24161L64.2277 2.91409V10.4493L64.2967 11.208V13.3979H62.6414Z"
        fill="white"
      />
      <path
        d="M9.32338 12.864H7.66804V1.81115H9.32338V12.864ZM13.3583 1.98358H3.65039V0.535156H13.3583V1.98358Z"
        fill="white"
      />
      <path d="M1.65534 12.864H0V0.535156H1.65534V12.864Z" fill="white" />
      <path
        d="M35.9666 4.01758L36.2732 5.68277L37.4935 4.51704L36.7693 6.04566L38.4371 5.82466L36.9588 6.63281L38.4371 7.44097L36.7693 7.21997L37.4935 8.74858L36.2732 7.58285L35.9666 9.24805L35.66 7.58285L34.4397 8.74858L35.1639 7.21997L33.4961 7.44097L34.9744 6.63281L33.4961 5.82466L35.1639 6.04566L34.4397 4.51704L35.66 5.68277L35.9666 4.01758Z"
        fill="white"
      />
    </svg>
  );
};

export default BrainsterNextLogo;
