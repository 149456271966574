import React from "react";
import styled from "styled-components/macro";

import groupPersonIcon from "./Static/group-person-icon.svg";

const StyledContainer = styled.article`
  border-radius: 16px;
  border: 1px solid #7497ff;
  background: #2a2f3d;
  padding: 10px;
  margin-left: 8px;
  height: 100px;

  div.max-students {
    border-radius: 12px;
    background: #20232e;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
    padding: 15px;
    display: flex !important;
    align-items: start;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
  }
  p.max-students-text {
    color: #e1e2e7;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.28px;
  }

  @media (max-width: 992px) {
    border-radius: 0;
    border: none;
    background: none;
    padding: 10px;
    height: auto;

    div.max-students {
      border-radius: 0;
      background: none;
      box-shadow: none;
      padding: 0;
      display: flex !important;
      align-items: center;
      gap: 4px;
      flex-direction: row;
      justify-content: center;
      height: auto;
    }
  }
`;

const HeroCardMaxStudents = () => {
  return (
    <StyledContainer className="max-students-container">
      <div className="max-students">
        <img src={groupPersonIcon} alt="" />
        <p className="max-students-text">Kвота 125 студенти</p>
      </div>
    </StyledContainer>
  );
};

export default HeroCardMaxStudents;
