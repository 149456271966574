import React from "react";
import styled from "styled-components/macro";

import theme from "../../../../Consts/Theme";
import applicant1 from "./Static/applicant-1.png";
import applicant2 from "./Static/applicant-2.png";
import applicant3 from "./Static/applicant-3.png";

// styled-components
const StyledContainer = styled.article`
  border-radius: 16px;
  border: 1px solid #7497ff;
  background: #373c4a;
  padding: 10px;
  p.total-applications {
    color: #fff;
    font-family: "NEXT ART";
    font-size: 53px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
  }
  p.applications-text {
    color: #fff;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.28px;
    margin-top: 8px;
  }


  @media (max-width: 992px) {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledContentWrapDesktop = styled.div`
  border-radius: 12px;
  background: #20232e;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 8px;

  img.img-applicant {
    margin-left: -5px;
  }

  @media (max-width: 992px) {
    display: none;
  }
`;
const StyledContentWrapMobile = styled.div`
  display: none;

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    height: 100%;
    border-radius: 12px;
    background: #20232e;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
    padding: 10px;

    .applicants-mobile-box {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
    }
    .applicants-mobile-box img.img-applicant {
      margin-left: -5px;
    }
  }
`;
const StyledCounter = styled.p`
  color: #fff;
  font-family: NEXT ART;
  font-size: 53px;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;
  position: relative;
  counter-reset: count;

  &.numbers::after {
    content: counter(count);
    animation: counter 1s linear alternate;
    counter-reset: count ${(props) => props.numbers};
  }

  @keyframes counter {
    10% {
      counter-increment: count 1;
    }
    20% {
      counter-increment: count 2;
    }
    30% {
      counter-increment: count 3;
    }
    40% {
      counter-increment: count 4;
    }
    50% {
      counter-increment: count 5;
    }
    60% {
      counter-increment: count 6;
    }
    70% {
      counter-increment: count 7;
    }
    80% {
      counter-increment: count 8;
    }
    90% {
      counter-increment: count 9;
    }
    100% {
      counter-increment: count 10;
    }
  }
`;

const HeroCardNumbers = ({ numbers, content, bgColor, preHeading }) => {
  return (
    <StyledContainer bgColor={bgColor}>
      <StyledContentWrapDesktop>
        <StyledCounter numbers={numbers} className="total-applications numbers"></StyledCounter>
        <div>
          <div>
            <img src={applicant1} alt="" />
            <img src={applicant2} alt="" className="img-applicant" />
            <img src={applicant3} alt="" className="img-applicant" />
          </div>
          <p className="applications-text">апликации досега</p>
        </div>
      </StyledContentWrapDesktop>
      {/* Mobile */}
      <StyledContentWrapMobile>
        <div className="applicants-mobile-box">
          <StyledCounter numbers={numbers} className="total-applications numbers"></StyledCounter>
          <div className="images-wrap-mobile">
            <img src={applicant1} alt="" />
            <img src={applicant2} alt="" className="img-applicant" />
            <img src={applicant3} alt="" className="img-applicant" />
          </div>
        </div>
        <p className="applications-text">апликации досега</p>
      </StyledContentWrapMobile>
    </StyledContainer>
  );
};

export default HeroCardNumbers;
