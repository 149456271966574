import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import groupImage from "./Static/groupStudents.png";
import groupImageMobile from "./Static/group-students-mobile.png";
import Wrapper from "../../../../Components/Wrapper/Wrapper";
import ButtonBasic from "../../../../Components/ButtonBasic/ButtonBasic";

// styled-components
const StyledContainer = styled.section`
  padding-bottom: 160px;

  @media (max-width: 992px) {
    padding-bottom: 80px;
  }
`;

const StyledBody = styled.section`
  margin-top: 80px;
  display: flex;
  height: 100%;
`;

const StyledContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-family: Raleway;

  p.description {
    color: #6a6a6a;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
  p.description.mobile {
    display: none;
  }
  h2.title {
    color: #000;
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
    margin-top: 24px;
  }
  div.cta-container {
    margin-top: 20px;
  }
  div.cta-container p.description {
    margin-top: 16px;
    color: #6a6a6a;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
  }
  p.cta-description-mobile {
    display: none;
  }
  @media (max-width: 768px) {
    width: 90%;
    margin-right: 5%;
    p.description {
      display: none;
    }
    p.description.mobile {
      font-size: 16px;
      display: block;
      margin-bottom: 16px;
    }
    h2.title {
      font-size: 32px;
      line-height: 34px;
      margin-top: 0;
    }
    p.cta-description-mobile {
      color: #6a6a6a;
      font-family: Raleway;
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.26px;
      margin-top: 16px;
      display: block;
    }

    div.cta-container {
      width: 100% !important;
    }
    div.cta-container button {
      width: 100% !important;
    }
  }
`;
const StyledImage = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: #dfe6ff;
  padding: 10px;
  img {
    width: 100%;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;
const StyledImageMobile = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: block;
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
    img {
      width: 100%;
    }
  }
`;
const SNPLSection = ({ handleScrollToSection }) => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <Wrapper>
        <StyledBody>
          <StyledContent>
            <div>
              <p className="description">
                Brainster Next- Единствен акредитиран IT факултет <br />
                на кој се учи преку практична работа!
              </p>
              <p className="description mobile">
                Brainster Next- Единствен акредитиран IT факултет на кој се учи
                преку практична работа!
              </p>
              <h2 className="title">
                Студирај за <strong>0 денари</strong>, плаќај по вработување!
              </h2>
            </div>

            <StyledImageMobile>
              <img src={groupImageMobile} alt="" />
            </StyledImageMobile>

            <div className="cta-container">
              <ButtonBasic
                variation="extra-large"
                filling={"altBlue"}
                text={t("homepage.hero_section.cta")}
                onClick={handleScrollToSection}
              />
              <p className="description">
                Загарантирано вработување преку работа на реални проекти, пракса
                во <br /> секоја академска година и блиска соработка со експерти
                од IT индустријата.
              </p>
              <p className="cta-description-mobile">
                Загарантирано вработување преку работа на реални проекти, пракса
                во секоја академска година и блиска соработка со експерти од IT
                индустријата.
              </p>
            </div>
          </StyledContent>
          <StyledImage>
            <img src={groupImage} alt="" />
          </StyledImage>
        </StyledBody>
      </Wrapper>
    </StyledContainer>
  );
};

export default SNPLSection;
