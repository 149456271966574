import React from "react";
import styled from "styled-components";

const StyledButtonContainer = styled.div`
  width: 528px;
  display: inline-block;
  padding: 10px; /* adjust as desired */ /* makes it pill-shaped */
  background-image: url(${({ bgImage }) => bgImage});
  background-repeat: no-repeat;
  height: 94px;



  @media (max-width: 425px) {
    width: 100%;
    overflow: hidden;
    border-radius: 50px;
  }
  @media (min-width: 426px) and (max-width: 992px) {
    overflow: hidden;
    border-radius: 50px;
  }
`;

const StyledButton = styled.button`
  background-color: white;
  outline: none;
  border: none;
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  span.text-button {
    font-family: Raleway;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    width: 90%;
    text-align: left;
  }
  span.svg-icon {
    align-self: center;
  }

  :active {
    background: ${(props) => props.hoverColor && props.hoverColor};
    border: 1px solid ${(props) => props.borderColor && props.borderColor};
  }
  :active span.svg-icon path,
  :active span.svg-icon line {
    stroke: ${(props) => props.theme.black};
  }

  :hover {
      transform: scale(1.01);
      /* background: ${(props) => props.hoverColor && props.hoverColor}; */
      /* border: 1px solid ${(props) => props.borderColor && props.borderColor}; */
    }
  @media (max-width: 768px) {
    width: 100%;
    overflow: hidden;
    border-radius: 50px;
    padding: 12px 16px;
    span.text-button {
      font-size: 14px;
    }
  }

  /* @media (min-width: 992px) {
    border-radius: 0;
    padding: 32px 50px;

    span.text-button {
      font-size: 20px;
    }

    :hover {
      transform: scale(1.01);
      background: ${(props) => props.hoverColor && props.hoverColor};
      border: 1px solid ${(props) => props.borderColor && props.borderColor};
    }
    :hover span.svg-icon path,
    :hover span.svg-icon line {
      stroke: ${(props) => props.theme.black};
    }
  } */
`;

const ButtonSquare = ({
  btnText,
  hoverColor,
  borderColor,
  backgroundImage,
}) => {
  return (
    <StyledButtonContainer bgImage={backgroundImage}>
      <StyledButton hoverColor={hoverColor} borderColor={borderColor}>
        <span className="text-button">{btnText}</span>
        <span className="svg-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
          >
            <path d="M0 1H19V19.5682" stroke="black" stroke-width="2" />
            <line
              y1="-1"
              x2="25.4595"
              y2="-1"
              transform="matrix(-0.715187 0.698933 -0.715187 -0.698933 18.209 1)"
              stroke="black"
              stroke-width="2"
            />
          </svg>
        </span>
      </StyledButton>
    </StyledButtonContainer>
  );
};

export default ButtonSquare;
