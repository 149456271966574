import { useContext } from 'react';

//npm imports
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import Accordion from '../../../../Components/Accordion/Accordion';

//component imports
import Wrapper from '../../../../Components/Wrapper/Wrapper';

//other imports
import { GlobalContext } from '../../../../Context/GlobalContext';
import { Caption50 } from '../../../../Components/Text/Text';
import { withTheme } from 'styled-components';

//styled-components
const StyledFaq = styled.div`
    background-color: ${props => props.theme.black};
    background-size: cover;
    background-position: center;
    padding: 220px 0;
    position: relative;
    z-index: 2;
    &>${Wrapper} > ${Caption50}{
        margin-bottom: 58px;
    }
    svg{
        margin-bottom: 74px;
    }
    @media (max-width: 586px){
        padding: 65px 0  100px;
        background-image: none;
        &>${Wrapper} > ${Caption50}{
            font-size: 32px;
            line-height: 40px;
        }
    }
`

//main component
const Faq = (props) => {

    const { t } = useTranslation();
    const { formatExpirationDate, formatResultsDate, term } = useContext(GlobalContext);

    return (
        <StyledFaq>
            <Wrapper>
                <Caption50 color={props.theme.white}>{t("homepage.faq.header")}</Caption50>
            </Wrapper>
            <div className="faq-container">
                {Array(5).fill().map((question, index) => <Accordion key={index} propShowMore={index === 0} header={t(`homepage.faq.${index}.question`)} content={t(`homepage.faq.${index}.answer`).replace("{EXPIRATION_DATE}", () => formatExpirationDate()).replace("{RESULTS_DATE}", () => formatResultsDate(term?.results_date)).replace("{DOMAIN}", process.env.REACT_APP_DOMAIN_URL)} />)}
            </div>
        </StyledFaq>
    )
}

export default withTheme(Faq);