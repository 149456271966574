import React, { useContext } from "react";
import styled, { withTheme } from "styled-components/macro";
import BrainsterNextLogo from "../../../../Components/BrainsterNextLogo/BrainsterNextLogo";
import ButtonBasic from "../../../../Components/ButtonBasic/ButtonBasic";
import theme from "../../../../Consts/Theme";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../../Context/GlobalContext";

// styled-components
const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 41;
  display: flex;
  align-items: center;
  /* background-color: black; */
  background: linear-gradient(180deg, #302f38 27.82%, rgba(48, 47, 56, 0) 100%);
  height: 133px;
`;
const StyledInnerWrap = styled.div`
  width: 90%;
  max-width: 1235px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledButtonsGroup = styled.div`
  display: flex;

  .go-to-test-button-container button:hover {
    background: linear-gradient(88deg, #7497ff -10.97%, #4b4bff 108.4%);
    transition: 0.3s ease-in-out;
  }

  button {
    &:hover {
      transform: scale(1.02);
    }
  }

  button:last-child {
    margin-left: 10px;
  }

  .btn-rules {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 23px;
    /* display: none; */
  }
  @media (max-width: 767px) {
    div.header-buttons-container {
      a {
        display: none;
      }
      .btn-rules {
        background-color: ${theme.altBlue};
        border: none;
        display: block;
      }
    }
  }

  @media (max-width: 550px) {
    div.header-buttons-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      a {
        margin-bottom: 12px;
        margin-right: 0;
      }
      
    }

  }
  @media (max-width: 992px) {
    .go-to-test-button-container {
      display: none;
    }
  }
`;

const HomePageHeader = ({ handleScrollToSection }) => {
  const { t } = useTranslation();
  const { showAllRulesPopup, setShowAllRulesPopup } = useContext(GlobalContext);
  return (
    <StyledHeader>
      <StyledInnerWrap>
        <BrainsterNextLogo color={theme.white} />
        <StyledButtonsGroup>
          {/* <div className="go-to-test-button-container">
            <ButtonBasic
              variation="extra-large"
              filling={"altBlue"}
              text="Аплицирај"
              onClick={handleScrollToSection}
            />
          </div> */}
          <div className="go-to-test-button-container">
            <ButtonBasic
              variation="extra-large"
              filling={"altBlue"}
              text={t("homepage.hero_section.cta")}
              onClick={handleScrollToSection}
            />
          </div>
          {/* <div className="header-buttons-container">
            <ButtonBasic
              variation={"extra-large"}
              text={t("general.rules")}
              className="btn-rules"
              onClick={() =>
                setShowAllRulesPopup({
                  ...showAllRulesPopup,
                  display: true,
                })
              }
            />
            <ButtonBasic
              variation="extra-large"
              filling={"altBlue"}
              text="Аплицирај"
              className="btn-rules"
              onClick={handleScrollToSection}
            />
          </div> */}
        </StyledButtonsGroup>
      </StyledInnerWrap>
    </StyledHeader>
  );
};

export default HomePageHeader;
